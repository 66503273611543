var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v(" " + _vm._s(_vm.job) + " ")]), _c('form', [_c('label', {
    attrs: {
      "for": "nameAndSurname"
    }
  }, [_vm._v("NAME AND SURNAME")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.nameAndSurname,
      expression: "nameAndSurname"
    }],
    attrs: {
      "type": "text",
      "id": "nameAndSurname"
    },
    domProps: {
      "value": _vm.nameAndSurname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.nameAndSurname = $event.target.value;
      }
    }
  }), _c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("EMAIL ADDRESS")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email,
      expression: "email"
    }],
    attrs: {
      "type": "email",
      "id": "email"
    },
    domProps: {
      "value": _vm.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.email = $event.target.value;
      }
    }
  }), _c('label', {
    attrs: {
      "for": "mobile"
    }
  }, [_vm._v("MOBILE")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mobile,
      expression: "mobile"
    }],
    attrs: {
      "type": "text",
      "id": "mobile"
    },
    domProps: {
      "value": _vm.mobile
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.mobile = $event.target.value;
      }
    }
  }), _c('label', {
    attrs: {
      "for": "coverLetter"
    }
  }, [_vm._v("COVER LETTER")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.coverLetter,
      expression: "coverLetter"
    }],
    attrs: {
      "id": "coverLetter",
      "cols": "30",
      "rows": "10"
    },
    domProps: {
      "value": _vm.coverLetter
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.coverLetter = $event.target.value;
      }
    }
  }), _c('label', {
    attrs: {
      "for": "cvOrResume"
    }
  }, [_vm._v("UPLOAD YOUR CV/RESUME")]), _c('input', {
    attrs: {
      "type": "file",
      "id": "cvOrResume"
    }
  }), _c('b-button', {
    staticStyle: {
      "width": "100%",
      "height": "48px",
      "margin-top": "1px"
    },
    attrs: {
      "pill": "",
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Go Back")]), _c('b-button', {
    staticStyle: {
      "width": "100%",
      "height": "48px",
      "background-color": "#0e9700",
      "margin-top": "1px"
    },
    attrs: {
      "pill": "",
      "variant": "primary"
    },
    on: {
      "click": _vm.submitApplicationForm
    }
  }, [_vm._v("Submit your application ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }