<template>
  <div>
    <h1>
      {{ job }}
    </h1>
    <form>
      <label for="nameAndSurname">NAME AND SURNAME</label>
      <input type="text" id="nameAndSurname" v-model="nameAndSurname" />

      <label for="email">EMAIL ADDRESS</label>
      <input type="email" id="email" v-model="email" />

      <label for="mobile">MOBILE</label>
      <input type="text" id="mobile" v-model="mobile" />

      <label for="coverLetter">COVER LETTER</label>
      <textarea
        id="coverLetter"
        cols="30"
        rows="10"
        v-model="coverLetter"
      ></textarea>

      <label for="cvOrResume">UPLOAD YOUR CV/RESUME</label>
      <input type="file" id="cvOrResume" />

      <b-button
        pill
        variant="outline-primary"
        style="width: 100%; height: 48px; margin-top: 1px"
        @click="$router.go(-1)"
        >Go Back</b-button
      >

      <b-button
        pill
        variant="primary"
        style="
          width: 100%;
          height: 48px;
          background-color: #0e9700;
          margin-top: 1px;
        "
        @click="submitApplicationForm"
        >Submit your application
      </b-button>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      job: "",
      nameAndSurname: "",
      email: "",
      mobile: "",
      coverLetter: "",
    };
  },
  created() {
    this.job = this.$route.path.split("/")[2];
  },
  methods: {
    submitApplicationForm() {
      console.log("Submit Application Form");
    },
  },
};
</script>
